import * as React from "react"
import { ResponsiveLine } from '@nivo/line'
import { Container, FormGroup, Switch, FormControlLabel } from '@material-ui/core'
import { LoadSheetData } from "./GymDataFunctions.js"
// import "@fontsource/urbanist"

const activities = ['yoga'];
const major = ['Squat', 'Bench Press', 'Deadlift', 'Shoulder Press'];
const maxReps = 30;

class GymActivityPlot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workoutLog: [],
            nivoData: {
                'working': [{ // DUMMY DATA 
                    'id': 'Loading Data!', 'data': [
                        { 'x': '01/01/2021', 'y': '60' },
                        { 'x': '01/02/2021', 'y': '70' },
                        { 'x': '01/03/2021', 'y': '60' },
                        { 'x': '01/04/2021', 'y': '70' }
                    ]
                }]
            },
            loaded: false,
            showMinor: true,
            plotType: 'working',
        };
    }

    updatePlotType = (event, value) => {
        let plotType = value ? 'onerm' : 'working'
        return this.setState({
            plotType: plotType
        });
    }

    updateShowMinor = (event, value) => {
        console.log('Showminor:', value)
        return this.setState({
            showMinor: !value
        });
    }

    prepareForNivo = (workoutLog) => {
        let working = workoutLog.filter(x => !!x.exercise).filter(b => {
            return !activities.includes(b['exercise'].toLowerCase())
        }).reduce(function (a, b) {
            let value = b['weight']
            let reshaped = { x: b['date'], y: value, reps: b['reps'], sets: b['sets'] }
            if (a[b['exercise']]) {
                a[b['exercise']]['data'].push(reshaped)
            } else {
                a[b['exercise']] = { 'id': b['exercise'], 'data': [reshaped] }
            }
            return a
        }, {});
        let onerm = workoutLog.filter(b => {
            return true
            // return !activities.includes(b['exercise'].toLowerCase())
        }).reduce(function (a, b) {
            // Use polynomial trend from https://strengthlevel.com/one-rep-max-calculator
            let reps = Math.min(b['reps'], maxReps)
            let multiplier = 0.0005 * reps ** 2 - 0.0306 * reps + 1.026
            let value = b['weight'] / multiplier
            let reshaped = { x: b['date'], y: value, reps: b['reps'], sets: b['sets'] }
            if (a[b['exercise']]) {
                a[b['exercise']]['data'].push(reshaped)
            } else {
                a[b['exercise']] = { 'id': b['exercise'], 'data': [reshaped] }
            }
            return a
        }, {});
        let data = {
            'working': Object.values(working),
            'onerm': Object.values(onerm)
        }
        return data
    }

    componentDidMount() {
        Promise.resolve(LoadSheetData(this.props.url)).then(response => {
            this.setState(
                {
                    workoutLog: response,
                    loaded: true,
                    plotType: 'working',
                });
            return response
        }).then(response => {
            const nivoData = this.prepareForNivo(response);
            this.setState({
                nivoData: nivoData
            })
        });
    }

    render() {
        const plotTypeTitle = { 'onerm': 'One Rep Max (Estimated)', 'working': 'Working Set Weight' };
        const { showMinor, plotType } = this.state;
        let show1rm = plotType === "onerm";
        let plotData = [];
        if (showMinor) plotData = this.state.nivoData[plotType];
        else plotData = this.state.nivoData[plotType].filter((obj) => major.includes(obj.id));
        console.log(plotData);
        return <Container id='cont'
            style={{ height: 400 }}>
            <h3>{plotTypeTitle[plotType]}</h3>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={show1rm} onChange={this.updatePlotType} />}
                    label="Estimated 1 Rep Max"
                />
                <FormControlLabel
                    control={<Switch checked={!showMinor} onChange={this.updateShowMinor} />}
                    label="Hide minor lifts"
                />
            </FormGroup>

            <ResponsiveLine id="LineChart"
                theme={{
                    // fontFamily: "Urbanist",
                    axis: {
                        textColor: '#eee',
                        ticks: { text: { fontSize: 12, } },
                        legend: { text: { fontSize: 17, }, },
                    },
                    legends: {
                        text: { fontSize: 14, },
                    },
                }}
                data={plotData}
                margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                enableGridX={false}
                xScale={{ type: 'time', 
                    format: "%m/%d/%Y",
                    precision: 'day',
                }}
                // xFormat="time:%b %d, %Y"
                // xFormat="time:%b %d, %Y"

                curve="monotoneX"
                yScale={{ type: 'linear', min: '0', max: '140', stacked: false, reverse: false }}
                gridYValues={[0, 20, 40, 60, 80, 100, 120, 140]}
                yFormat={value =>
                    `${Number(value).toLocaleString('en-UK', {
                        minimumFractionDigits: 2,
                    })} kg`
                }
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    // format: "%m %Y",
                    // tickValues: "every year",
                    format: '%b %Y',
                    tickValues: 'every 3 months',
                    // tickRotation: -90,
                    legendPosition: 'middle',
                    legend: "Date",
                    legendOffset: 40

                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                    tickPadding: 12,
                    tickRotation: 0,
                    legend: 'Weight',
                    legendOffset: -60,
                    legendPosition: 'middle',
                    format: value => `${value} kg`
                }}
                pointSize={7}
                pointColor={{ from: 'color', modifiers: [] }}
                pointBorderWidth={0}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={false}
                enableSlices="x"
                motionConfig="default"
                colors={{ scheme: 'dark2' }}
                sliceTooltip={({ slice }) => {
                    return (
                        <div
                            style={{
                                background: 'white',
                                padding: '9px 12px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                            }}
                        >
                            {slice.points.map(point => (
                                <div key={point.id}>
                                    < div style={{ color: point.serieColor, padding: '3px 0', }}>
                                        <strong>{point.serieId}:</strong>
                                    </div>
                                    {point.data.yFormatted} for {point.data.sets} x {point.data.reps}reps
                                </div>
                            ))}
                        </div>
                    )
                }}
                legends={
                    [
                        {
                            itemBackground: 'rgba(255,255,255, .8)',
                            anchor: 'bottom-left',
                            direction: 'column',
                            justify: false,
                            translateX: 5,
                            translateY: -5,
                            itemsSpacing: 2,
                            itemDirection: 'left-to-right',
                            itemWidth: 120,
                            itemHeight: 20,
                            // itemOpacity: 0.2,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: .7
                                    }
                                }
                            ]
                        }
                    ]}
            /></Container>
    }
}
export default GymActivityPlot
