const exerciseNames = {
    "pullup": "Pull Up",
    "squat": "Squat",
    "goodmorning": "Good Morning",
    "shoulderpress": "Shoulder Press",
    "deadlift": "Deadlift",
    "seatedrows": "Seated Row",
    "bench": "Bench Press",
    "yoga": "Yoga",
    "row": "Row"
}

let isDate = (dateCandidate) => {
    if ((dateCandidate.split(".").length - 1 === 2) || (dateCandidate.split("-").length - 1 === 2)) {
        return true
    }
}

let processData = (responseText) => {
    let lines = responseText.split('\r\n');
    let date = new Date('1993-01-11');
    let workoutLog = [];
    let min_date = new Date('2021', '06', '01');
    lines.forEach((item) => {
        let cleaned = item.replaceAll(',', '')
        if (cleaned.length > 0) {
            if (isDate(item)) {
                var arr = item.replaceAll(',', '').split(/[.]/);
                date = new Date(arr[0], arr[1] - 1, arr[2]);
                // date = new Date(item.replaceAll(',', '').replaceAll('.', '/'))
            }
            else if (date > min_date) {
                let exercise, sets, reps, weight;
                [exercise, sets, reps, weight] = item.split(',');
                exercise = exercise.replace(' ', '').toLowerCase().trim()
                workoutLog.push({
                    'date': date.toLocaleDateString(),
                    'exercise': exerciseNames[exercise],
                    'sets': sets,
                    'reps': reps,
                    'weight': weight
                })
            }
        }
    })
    return workoutLog
}


export function LoadSheetData(url) {
    let dataLoader = fetch(url).then(response => {
        return response.text()
    }).then(responseText => {
        return processData(responseText)
    })
    return dataLoader
}


export function LoadCSV(path) {
    let dataLoader = fetch(path).then(response => {
        console.log('RESPONSE: ', response)
        return response.text()
    }).then(responseText => {
        console.log(responseText)
        return processData(responseText)
    })
    return dataLoader
}



